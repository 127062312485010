import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEyeClose = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#eye-close_svg__a)" fill="currentColor">
      <path d="m15.355 12.491 2.72 2.72.013-.143a2.592 2.592 0 0 0-2.59-2.59l-.143.013Z" />
      <path d="M15.498 10.751a4.319 4.319 0 0 1 4.317 4.317 4.25 4.25 0 0 1-.306 1.576l2.525 2.526A10.255 10.255 0 0 0 25 15.068c-1.498-3.79-5.18-6.475-9.502-6.475-1.209 0-2.366.215-3.44.604l1.864 1.86a4.34 4.34 0 0 1 1.576-.306ZM6.863 8.398l1.97 1.969.392.393A10.226 10.226 0 0 0 6 15.068c1.494 3.79 5.18 6.476 9.498 6.476 1.338 0 2.616-.259 3.786-.73l.367.367 2.517 2.522 1.1-1.097L7.965 7.297l-1.1 1.101Zm4.775 4.77 1.334 1.335a2.733 2.733 0 0 0-.064.565 2.592 2.592 0 0 0 2.59 2.59c.194 0 .38-.025.561-.064l1.334 1.334a4.248 4.248 0 0 1-1.895.457 4.319 4.319 0 0 1-4.317-4.317c0-.682.172-1.32.457-1.9Z" />
    </g>
    <defs>
      <clipPath id="eye-close_svg__a">
        <path transform="translate(6 6)" d="M0 0h19v19H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgEyeClose;
