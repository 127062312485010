import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgCheckCircleFill = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 15C4 8.928 8.928 4 15 4s11 4.928 11 11-4.928 11-11 11S4 21.072 4 15Zm15.036-3.884c-.255-.002-.5.09-.685.255h.001a.336.336 0 0 0-.026.027l-4.547 5.46-2.742-2.582a.983.983 0 0 0-.319-.215 1.033 1.033 0 0 0-1.094.191.923.923 0 0 0-.216.308.879.879 0 0 0 .014.723.928.928 0 0 0 .227.301l3.465 3.265a.988.988 0 0 0 .327.204 1.036 1.036 0 0 0 .768-.013.982.982 0 0 0 .319-.215l5.224-6.158a.895.895 0 0 0 .258-.65.901.901 0 0 0-.294-.636 1.013 1.013 0 0 0-.68-.265Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheckCircleFill;
