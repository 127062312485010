import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgEye = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M18.08 15.472c0 .78-.352 1.528-.981 2.08s-1.481.862-2.37.862c-.89 0-1.742-.31-2.371-.862s-.982-1.3-.982-2.08.353-1.528.982-2.08 1.481-.862 2.37-.862c.89 0 1.742.31 2.37.862.63.552.983 1.3.983 2.08Z"
      fill="currentColor"
    />
    <path
      d="M4 15.472S8.023 9 14.727 9c6.705 0 10.728 6.472 10.728 6.472s-4.023 6.471-10.728 6.471C8.023 21.943 4 15.472 4 15.472Zm10.727 4.118c1.245 0 2.439-.434 3.319-1.206.88-.773 1.375-1.82 1.375-2.912 0-1.093-.495-2.14-1.375-2.912-.88-.773-2.074-1.207-3.319-1.207-1.244 0-2.438.434-3.318 1.207-.88.772-1.375 1.82-1.375 2.912 0 1.092.495 2.14 1.375 2.912.88.772 2.074 1.206 3.318 1.206Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEye;
