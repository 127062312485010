enum CurrencyEnum {
	usd = "USD",
	eur = "EUR",
	pen = "PEN",
	brl = "BRL",
	mxn = "MXN",
	cop = "COP",
	clp = "CLP",
	ars = "ARS",
	gtq = "GTQ",
	nio = "NIO",
	crc = "CRC",
	hnl = "HNL",
	svc = "SVC",
}

const currencyFormat = (value: number, currency: string) => {
	let country = "en-US";
	let newCurrency = currency;
	switch (currency) {
		case CurrencyEnum.ars:
			country = "es-AR";
			break;
		case CurrencyEnum.brl:
			country = "es-BR";
			break;
		case CurrencyEnum.clp:
			country = "es-CL";
			break;
		case CurrencyEnum.cop:
			country = "es-CO";
			break;
		case CurrencyEnum.eur:
			country = "es-ES";
			break;
		case CurrencyEnum.mxn:
			country = "es-MX";
			break;
		case CurrencyEnum.pen:
			country = "es-PE";
			break;
		case CurrencyEnum.usd:
			country = "en-US";
			break;
		case CurrencyEnum.gtq:
			country = "es-GT";
			break;
		case CurrencyEnum.nio:
			country = "es-NI";
			break;
		case CurrencyEnum.crc:
			country = "es-CR";
			break;
		case CurrencyEnum.hnl:
			country = "es-HN";
			break;
		case CurrencyEnum.svc:
			country = "es-SV";
			newCurrency = CurrencyEnum.usd;
			break;
		default:
			country = "en-US";
			newCurrency = CurrencyEnum.usd;
			break;
	}

	return new Intl.NumberFormat(country, {
		style: "currency",
		currency: newCurrency,
	}).format(value);
};

export default currencyFormat;
